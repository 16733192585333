import { FC, useMemo } from 'react';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { CurrencyInputActionResponse } from './CurrencyInputAction';
import { NumericFormat } from 'react-number-format';
import CurrencyList from 'currency-list';

const CurrencyInputPreview: FC<ActionPreviewBaseProps<CurrencyInputActionResponse>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  if (answerOnly) {
    return (
      <>
        {response ? (
          <>
            <span className="text-gray-3">{response.currencyCode}</span> {CurrencyList.get(response.currencyCode).symbol}{' '}
            <NumericFormat displayType="text" value={response.amount} valueIsNumericString thousandSeparator=" " />
          </>
        ) : (
          '-'
        )}
      </>
    );
  }

  return (
    <ActionPreview
      data-cy="text-input-preview"
      question={title}
      description={previewDescription}
      answer={response ? response.currencyCode + ' ' + response.amount : '-'}
    />
  );
};

export default CurrencyInputPreview;
